/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';

import Axios from 'axios';
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const cookies = new Cookies();

// console.log('process.env.NODE_ENV');
console.log(process.env);

Axios.defaults.baseURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000/v1/'
    : 'https://api.captemo.com/v1/';
// Axios.defaults.baseURL = 'https://vdata.captemo.com/v1/';// for dev use
// Axios.defaults.baseURL = 'https://api.captemo.com/v1/';
Axios.defaults.headers.common['x-access-token'] = cookies.get('auth_token');
// Add a response interceptor
Axios.defaults.validateStatus = status => {
  return true;
};

Axios.interceptors.response.use(
  response => {
    // Do something with response data
    // const resData = JSON.parse(response);
    // console.log('response');
    // console.log(response);
    // console.log('**********************');

    const resData = response.data;
    if (
      resData &&
      resData.success === false &&
      (resData.message === 'jwt expired' ||
        resData.message === 'jwt malformed' ||
        resData.message === 'invalid signature')
    ) {
      cookies.remove('auth_token');
      // localStorage.removeItem('auth_token');
      window.location.href = '/login';
    } else if ((!resData || resData.success === false) && !resData.message) {
      console.log('response >> ', response);
      toast.error(response.statusText.toString());
    }
    return response;
  },
  error => {
    console.log('error >> ', error);
    toast.error(error.message);
    // Do something with response error
    return Promise.reject(error);
  }
);

// const loading = () => (
//   <div className="animated fadeIn pt-3 text-center">Loading...</div>
// );
const loading = () => <div className="sk-spinner sk-spinner-pulse"></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={props => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={props => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={props => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={props => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={props => <DefaultLayout {...props} />}
            />
          </Switch>
          <ToastContainer />
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
